<template>
  <section>
    <v-list two-line v-if="lavorazioni.length">
      <template v-for="(lavorazione, index) in lavorazioni">
        <v-list-tile
          @click.stop="openLavDialog(lavorazione)"
          :key="'tile-'+lavorazione.ordini_lavorazioni_id">
          <v-list-tile-content>
            <v-list-tile-title v-text="lavorazione.descrizione" />
            <v-list-tile-sub-title v-text="lavorazione.note" />
            <v-list-tile-sub-title v-text="'Quantità: '+lavorazione.qta" v-if="lavorazione.qta" />
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text v-text="'€ '+(lavorazione.qta * lavorazione.prezzo)" />
            <v-icon v-if="!readOnly()" @click="eliminaLav = true; lavorazione = lavorazione" color="error">delete</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="lavorazione.ordini_lavorazioni_id" v-if="index < lavorazioni.length - 1" />
      </template>
    </v-list>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!lavorazioni.length">
      Nessuna lavorazione trovata
    </v-alert>
    <!-- modale lavorazione -->
    <v-dialog
      v-model="showLav"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showLav=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formLavorazione" v-model="validFormLavorazione" lazy-validation>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-select
                        :items="allLavorazioni"
                        :disabled="readOnly() || !lavorazione.nuovo"
                        item-text="descrizione"
                        item-value="_id"
                        label="Lavorazione"
                        v-model="lavorazione.voce_id"
                        required />
                      <currency-input
                        v-show="lavorazione.voce_id"
                        :disabled="readOnly() || !lavorazione.nuovo"
                        v-model="lavorazione.prezzo"
                        label="Prezzo"
                        name="prezzo" />
                      <v-text-field
                        :label="descQta[lavorazione.forfait]"
                        v-model="lavorazione.qta"
                        :disabled="readOnly() || !lavorazione.nuovo || lavorazione.forfait==2"
                        ref="qta"
                        v-show="lavorazione.voce_id"
                        :rules="[$rules.numeric, $rules.maxlength(10)]" />
                      <!-- v-text-field
                        label="Totale"
                        v-model="lavorazione.totale"
                        v-show="lavorazione.voce_id"
                        :disabled="true" / -->
                      <v-textarea
                        auto-grow
                        label="Note in fattura"
                        v-show="lavorazione.voce_id"
                        :disabled="readOnly() || !lavorazione.nuovo"
                        v-model="lavorazione.note"
                        :rules="[$rules.maxlength(4000)]" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    v-if="lavorazione.nuovo"
                    class="ml-0"
                    color="primary"
                    :disabled="readOnly() || !validFormLavorazione"
                    @click.native="save">
                    Salva
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina lavorazione -->
    <v-dialog v-model="eliminaLav" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>Stai per cancellare la lavorazione <strong>{{ lavorazione.descrizione }}</strong>.</v-card-text>
        <v-card-actions>
          <v-btn @click.native="eliminaLav = false">Annulla</v-btn>
          <v-btn color="error" @click.native="removeLav">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip left v-if="!readOnly()">
      <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click="openLavDialog()">
        <v-icon color="white">add</v-icon>
      </v-btn>
      <span>Nuova lavorazione</span>
    </v-tooltip>
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import currencyInput from '@/components/common/CurrencyInput'
import _clone from 'lodash/clone'
import _debounce from 'lodash/debounce'

export default {
  props: {
    ordine: Object
  },
  data: () => ({
    allLavorazioni: [],
    lavorazioni: [],
    lavorazione: {},
    descQta: ['Quantità', 'Numero bancali', 'Quantità'],
    noQta: false,
    showLav: false,
    eliminaLav: false,
    validFormLavorazione: false
  }),
  components: {
    currencyInput
  },
  computed: {
    modalTitle () {
      return this.lavorazione.nuovo ? 'Nuova lavorazione' : this.lavorazione.descrizione
    }
  },
  methods: {
    getLavorazioni () {
      this.$plsqlReadAll('voce', { cliente: this.ordine.cliente_id })
        .then(response => { this.allLavorazioni = response.data.filter(l => l.attivo === 1) })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
      this.$plsqlMethod('ordine', 'get_lavorazioni', { id: this.ordine._id })
        .then(response => {
          this.lavorazioni = response.data
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    save () {
      if (!this.readOnly() && this.$refs.formLavorazione.validate()) {
        this.$plsqlMethod('ordine', 'save_lavorazione', this.lavorazione)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Lavorazione salvata'
            })
            this.getLavorazioni()
            this.showLav = false
          })
          .catch(err => {
            if (err.data.errCode === 'ORA-00001') {
              $EventBus.$emit('message', { type: 'error', text: 'Questa lavorazione è già stata associata all’ordine' })
            } else {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            }
          })
      }
    },
    removeLav () {
      if (!this.readOnly()) {
        this.$plsqlMethod('ordine', 'delete_lavorazione', { ordine: this.ordine._id, id: this.lavorazione.voce_id })
          .then(response => {
            $EventBus.$emit('message', { type: 'success', text: 'Lavorazione eliminata' })
            this.eliminaLav = false
            this.getLavorazioni()
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    openLavDialog (lavorazione) {
      this.lavorazione = lavorazione ? _clone(lavorazione) : { ordine_id: this.ordine._id, prezzo: 0, nuovo: 1, forfait: 0, qta: 1 }
      if (!this.eliminaLav) this.showLav = true
    },
    changedLavorazione (newVal, oldVal) {
      if (this.lavorazione && this.lavorazione.nuovo) {
        let lav = this.allLavorazioni.find(function (l) {
          return l._id === parseInt(newVal, 10)
        })
        if (lav) {
          this.lavorazione.forfait = lav.forfait
          this.lavorazione.prezzo = lav.prezzo
          this.lavorazione.qta = 1
          this.lavorazione.totale = lav.prezzo
        }
      }
    },
    readOnly () {
      return (!!this.ordine.has_fattura)
    }
    /* changedQta (newVal, oldVal) {
      if (this.lavorazione && this.lavorazione.nuovo) {
        let lav = this.allLavorazioni.find((l) => { return l._id === this.lavorazione.voce_id })
        if (lav) {
          this.lavorazione.forfait = lav.forfait
          if (lav.forfait < 2) {
            this.lavorazione.totale = newVal * lav.prezzo
          }
        }
      }
    },
    changedPrezzo (newVal, oldVal) {
      console.log(this.lavorazione)
      if (this.lavorazione && this.lavorazione.qta) {
        this.lavorazione.totale = newVal * this.lavorazione.qta
      }
    } */
  },
  watch: {
    /* 'lavorazione.qta': function (newVal, oldVal) {
      this.debouncedChangedQta(newVal, oldVal)
    },
    'lavorazione.prezzo': function (newVal, oldVal) {
      this.debouncedChangedPrezzo(newVal, oldVal)
    }, */
    'lavorazione.voce_id': function (newVal, oldVal) {
      this.debouncedChangedLavorazione(newVal, oldVal)
    }
  },
  created: function () {
    /* this.debouncedChangedQta = _debounce(this.changedQta, 500)
    this.debouncedChangedPrezzo = _debounce(this.changedPrezzo, 500) */
    this.debouncedChangedLavorazione = _debounce(this.changedLavorazione, 500)
  },
  mounted () {
    this.getLavorazioni()
  }
}
</script>
